// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-appointments-js": () => import("./../../../src/pages/appointments.js" /* webpackChunkName: "component---src-pages-appointments-js" */),
  "component---src-pages-find-us-js": () => import("./../../../src/pages/find-us.js" /* webpackChunkName: "component---src-pages-find-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-clinical-sleep-psychologist-js": () => import("./../../../src/pages/our-clinical-sleep-psychologist.js" /* webpackChunkName: "component---src-pages-our-clinical-sleep-psychologist-js" */),
  "component---src-pages-our-specialist-physicians-js": () => import("./../../../src/pages/our-specialist-physicians.js" /* webpackChunkName: "component---src-pages-our-specialist-physicians-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

